import type { ValuesOf } from '@epi/types';

import { InitialPermissionsMap } from '../gateways/initial-permissions-map';

export const UserRole = {
  Owner: 'OWNER',
  Admin: 'ADMIN',
  Editor: 'EDITOR',
} as const;

export type UserRoles = ValuesOf<typeof UserRole>;
export type PermissionsMap = typeof InitialPermissionsMap;

export interface Role {
  uid: UserRoles;
  permissions: PermissionsMap;
}

export interface PermissionsViewModel {
  groupKey: keyof PermissionsMap;
  permissions: Array<{ key: string; value: boolean }>;
}
