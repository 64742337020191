import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from '@angular/fire/firestore';
import { Observable, firstValueFrom, from, map } from 'rxjs';

import { createUserRole } from '../models/create-user-role.dto';
import type { UpdatePermissionDto } from '../models/update-permission.dto';
import { Role, UserRole, UserRoles } from '../models/user-role';

@Injectable({ providedIn: 'root' })
export class PermissionsGateway {
  protected firestore = inject(Firestore);

  protected BASE_PATH = 'roles';

  protected get collectionRef() {
    return collection(this.firestore, this.BASE_PATH);
  }

  /**
   * Call this function only once to initialize data in database,
   * in APP_INITIALIZER
   */
  async initializeRoles(): Promise<void> {
    const permissionsExist = await firstValueFrom(this.listOnce());
    if (permissionsExist?.length > 0) {
      console.warn('Permissions are already defined. Aborting.');
      return;
    }
    console.log('Initialize permissions data');
    const createRolesTasks = Object.values(UserRole).map((role) => this.create(createUserRole(role)));
    await Promise.all(createRolesTasks).catch(console.error);
    console.log('Permissions data initialized');
  }

  listOnce(): Observable<Role[]> {
    return from(getDocs(this.collectionRef)).pipe(
      map((res) => {
        return res.docs.map((d) => d.data() as Role);
      })
    );
  }

  getRole(roleUid: UserRoles): Observable<Role> {
    return from(getDoc(doc(this.firestore, `${this.BASE_PATH}/${roleUid}`))).pipe(map((snapshot) => snapshot.data() as Role));
  }

  async updatePermission({ role, groupKey, permission, value }: UpdatePermissionDto): Promise<void> {
    const ref = doc(this.firestore, `${this.BASE_PATH}/${role}`);
    const updates = {
      [`permissions.${groupKey}.${permission}`]: value,
    };
    await updateDoc(ref, { ...updates });
  }

  private async create(dto: Role): Promise<void> {
    try {
      const ref = doc(this.firestore, `${this.BASE_PATH}/${dto.uid}`);

      await setDoc(ref, dto);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
}
