import { clone } from 'ramda';

import { InitialPermissionsMap } from '../gateways/initial-permissions-map';
import type { Role, UserRoles } from './user-role';

export function createUserRole(role: UserRoles): Role {
  return {
    uid: role,
    permissions: clone(InitialPermissionsMap),
  };
}
