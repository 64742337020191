export const InitialPermissionsMap = {
  dashboard: {
    readDashboard: false,
    readApplicationsAccesses: false,
  },
  content: {
    readContent: true,
    createContent: true,
    updateContent: true,
    deleteContent: true,
    createPricingPlans: true,
    deletePricingPlans: true,
  },
  seminars: {
    readSeminars: true,
    createSeminars: true,
    updateSeminars: true,
    deleteSeminars: true,
    sendNewsletter: false,
    updateAttendeeStatus: true,
    sendAttendeesCommunication: true,
  },
  blog: {
    readBlogPosts: false,
    createBlogPosts: false,
    updateBlogPosts: false,
    deleteBlogPosts: false,
  },
  students: {
    readStudents: true,
    createStudents: true,
    updateStudents: true,
    deleteStudents: true,
    sendCommunication: true,
  },
  medias: {
    readMedias: true,
    createMedias: true,
    deleteMedias: true,
  },
  settings: {
    readClassLocations: true,
    createClassLocations: true,
    deleteClassLocations: true,
    readUsers: false,
    createUsers: false,
    deleteUsers: false,
    setUsersRoles: false,
    readPermissions: false,
    updatePermissions: false,
  },
};
